import { useFetcher } from "@remix-run/react";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";

export enum Theme {
  DARK = "dark",
  LIGHT = "light",
}

export function isTheme(theme: unknown): theme is Theme {
  return theme === "light" || theme === "dark";
}

interface ThemeContextProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextProps>({
  theme: Theme.DARK,
  setTheme: () => {},
  toggleTheme: () => {},
});

export default function ThemeProvider({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: Theme;
}) {
  const fetcher = useFetcher();
  const [theme, setTheme] = useState<Theme>(initialState);
  const renderCount = useRef(0);

  useEffect(() => {
    const root = document.documentElement;
    root.classList.remove("light", "dark");
    root.classList.add(theme);
    if (renderCount.current < 2) {
      renderCount.current++;
    } else {
      // Hack to not trigger multiple re-renders on loading app
      // all components render twice, so this will only trigger after the
      // initial 2 renders
      const formData = new FormData();
      formData.append("theme", theme);
      formData.append("path", window.location.href);
      fetcher.submit(formData, { method: "POST", action: "/set-theme" });
    }
  }, [theme]);

  const toggleTheme = () => {
    const nextState = theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
    setTheme(nextState);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
}
